/** @jsx jsx */
import {responsiveTitle1} from "../styles/typography.module.css"
import BlockContent from "./block-content"
import Container from "./container"
import PreviewGrid from "./lists-preview/preview-grid"
import localize from "./localize"
import {serviceHero, serviceHeroTitle, serviceBody, serviceBlocks} from "./service.module.css"
import classNames from "classnames"
import SanityImage from "gatsby-plugin-sanity-image"
import {Flex, jsx} from "theme-ui"

function Service({_rawBody, title, mainImage, relatedServices, locale, slug}) {
  return (
    <article>
      {mainImage && (
        <div className={serviceHero}>
          <h1 className={classNames([serviceHeroTitle, responsiveTitle1])}>{title}</h1>
          <SanityImage width={1200} {...mainImage} loading="eager" />
        </div>
      )}
      <Container>
        <div className={serviceBody}>
          <div className={serviceBlocks}>
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
          </div>
          <aside>
            {relatedServices && <PreviewGrid nodes={relatedServices} locale={locale} />}
          </aside>
        </div>
      </Container>
    </article>
  )
}

export default localize(Service, {propsTotranslate: ["title", "slug"]})