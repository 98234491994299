import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import LayoutContainer from "../components/layout-container";
// import Layout from '../containers/layout'
import localize from "../components/localize";
import SEO from "../components/seo";
import Service from "../components/service";
import SeoContext from "../containers/SeoContext";
import { graphql } from "gatsby";
import React, { Context, useContext } from "react";


// TODO CREATE REACT CONTEXT FOR SEO.
// const seoInfo = React.createContext; //or smth.
//  const context = useContext(contextValue)
const ServiceTemplate = ({data, errors, pageContext}) => {
  const {locale, altLang } = pageContext
  const service = data && data.service
  const site = data && data.site
  console.log("service", service)
  // const locale = pageContext.locale
  // const altLangUrl = pageContext.altLang
  // const templateType = pageContext.template

  // const seo = {
  //   title: service?.title || site?.title,
  //   description: service?.seo?.description || site.description,
  //   image:
  // }
  const seo = {
    title: service?.seo?.title || site?.title,
    description: service?.seo?.description || site.description,
    image: service?.seo?.image,
    altLang: altLang,
    // altLangUrl: pageContext.altLangUrl,
    locale: locale
  }
  // const [SeoContext, setSeoContext] = useState({})

  return (
    <SeoContext.Provider value={seo}>
      <LayoutContainer locale={locale} altLangUrl={altLang}>
        {errors && <SEO title="GraphQL Error" />}
        {service && (
          <SEO
            title={seo.title}
            lang={locale}
            description={seo.description}
            altLang={altLang}
            altLangUrl={altLang}
            {...pageContext}
          />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}
        {service && <Service locale={locale} {...service} />}
      </LayoutContainer>
    </SeoContext.Provider>
  )
}

export default localize(ServiceTemplate)
export const query = graphql`
  query ServiceTemplateQuery($id: String!) {
    service: sanityService(id: {eq: $id}) {
      seo: openGraph {
        title
        description
        image {
          asset {
            _id
          }
        }
      }
      id
      categories {
        _id
        title
      }
      relatedServices {
        title {
          _type
          en
          es
        }
        _type
        mainImage {
          ...ImageWithPreview
          asset {
            altText
            _id
          }
        }
        _id
        slug {
          _type
          en {
            current
          }
          es {
            current
          }
        }
      }
      mainImage {
        ...ImageWithPreview
        asset {
          altText
          _id
        }

        #  ...SanityImage
        #  alt
        # asset{
        #   altText
        #   _id
        #   }
        #  asset {
        #    _id
        #    fluid(maxWidth: 800) {
        #      ...GatsbySanityImageFluid
        #    }
        #  }
        #  crop {
        #    _key
        #    _type
        #    top
        #    bottom
        #    left
        #    right
        #  }
        #  hotspot {
        #    _key
        #    _type
        #    x
        #    y
        #    height
        #    width
        #  }

        #  alt
      }
      title {
        _type
        en
        es
      }
      slug {
        _type
        en {
          current
        }
        es {
          current
        }
      }
      _rawBody(resolveReferences: {maxDepth: 10})
    }
    site: sanitySiteSettings {
      title
    }
  }
`